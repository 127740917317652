<template>
  <main>
    <div class="container py-4">
      <nav class="navbar navbar-expand-sm" aria-label="Navbar anggota">
        <div class="container-fluid">
          <router-link to="/admin" class="navbar-brand">
            <img
              src="@/assets/logo.png"
              alt="Logo Taekwondo Indonesia"
              srcset=""
              width="52px"
            />
          </router-link>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample03"
            aria-controls="navbarsExample03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="dropdown">
            <a
              class="nav-link dropdown-toggle white--text"
              id="userMenuDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"
                width="40"
                height="40"
                class="rounded-circle"
              />
            </a>
            <ul class="dropdown-menu" aria-labelledby="userMenuDropdown">
              <li>
                <router-link class="dropdown-item" to="/anggota"
                  >Profilku</router-link
                >
              </li>
              <li>
                <a href="" class="dropdown-item" @click="logout">Keluar</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <router-view />

      <footer class="pt-3 mt-4 text-muted border-top">
        © 2023 Taekwondo Indonesia
      </footer>
    </div>
  </main>
</template>

<script>
import { authenticationService } from "@/services";
export default {
  methods: {
    logout() {
      authenticationService.logout();
      location.reload();
    },
  },
};
</script>

<style scoped></style>
